<template>
    <v-container class="panel-wrapper panel-file pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="File Manager">
                    <v-layout class="frame-wrap">
                        <iframe ref="frame" @load="() => {$onload()}" frameborder="0" style="width: 100%;height: 100%" class="frame" :src="iframeData.url"></iframe>
                    </v-layout>
                </TabCard>

                <v-card elevation="0">
                    <v-card-text>
                        <Help :mod="['menu_tab_file_manager']" :product="appInfo.product_name"></Help>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters,mapState} from 'vuex' //mapActions  mapGetters  mapState
    import TabCard from '@/components/TabCard.vue'
    import Help from '@/components/Help'
    export default {
        name: "fileManager",
        components: { TabCard,Help },
        computed: {
            iframeData(){
                let filebrowser_info = this.getService().filebrowser_info
                return {
                    url: `https://${filebrowser_info.domain_names[0]}/filebrowser`,
                    name: filebrowser_info.username,
                    password:filebrowser_info.password
                }
            },
            ...mapState('application',["appInfo"]),
            ...mapGetters('application', ['getService'])
        },
        methods: {
            $onload(){
                let {name,password} = this.iframeData
                 this.$refs.frame.contentWindow.postMessage(JSON.stringify({
                     name,
                     password
                 }),'*')
            }
        },
        created(){
            this.$SDK.track({pageName:'File Manager',productName:this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">
    .panel-file{
        .frame-wrap{
            height: calc(100vh - 200px);
        }
    }
</style>
