var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-file pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "File Manager" } },
                [
                  _c("v-layout", { staticClass: "frame-wrap" }, [
                    _c("iframe", {
                      ref: "frame",
                      staticClass: "frame",
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { frameborder: "0", src: _vm.iframeData.url },
                      on: {
                        load: function() {
                          _vm.$onload()
                        }
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card",
                { attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("Help", {
                        attrs: {
                          mod: ["menu_tab_file_manager"],
                          product: _vm.appInfo.product_name
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }